@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'PoppinsLight';
  src: local('PoppinsLight'), url(./fonts/Poppins-Light.ttf) format('truetype');
}
html {
  height: 100%;
}
body {
  text-shadow: 0px 0px 15px black;
  margin: 0;
  font-family: PoppinsRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: initial;
  background-image: url("img/background-tiny.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  overflow: hidden;
  color: #fff;
}





code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
}
