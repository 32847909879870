
#launchContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-around;

}

#launchContainer > div {
  text-align: center;
}
h1 {
  font-family: PoppinsLight;
  color: #fff;
  font-size: 35px!important;
  line-height: 34px!important;
  margin-top: 0;
  text-align: left;
  font-weight: 200!important;
}
h2{
  font-family: PoppinsRegular;
  color: #fff;
  font-size: 2.5rem;
}
p{
  font-family: PoppinsRegular;
  color: #fff;
  font-size: 28px;
  line-height: 34px;
  margin-top: 0;
}

.ui.circular.button {
  border-radius: 10em!important;
  background: rgba(0,0,0,0.3)!important;
  width: 100px!important;
  height: 100px!important;
  border: 2px solid white!important;
}
.experience{
  font-size: 18px;
  margin-top: 10px;
}